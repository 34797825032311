import ServiceHelper from "./service.helper";

export default class AccountService {
    static BASE_URL = '/adminapi';

    static getDashboard(payload) {
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/dashboard`);
    }

    static createVirtualAccount(payload) {
        return ServiceHelper.handlePostRequest(`/virtual_account/`, payload);
    }

    static getVirtualAccounts() {
        return ServiceHelper.handleGetRequest('/virtual_account')
    }

    static getAccounts() {
        return ServiceHelper.handleGetRequest('/accounts')
    }

    static getBankList() {
        return ServiceHelper.handleGetRequest(`/add_bank/?action=get_banks`);
    }

    static getCountries() {
        return ServiceHelper.handleGetRequest(`/country_list/`);
    }

    static verifyBvn(bvn, dob) {
        return ServiceHelper.handleGetRequest(`/verify_bvn/?bvn=${bvn}&dob=${dob}`);
    }
    
    static validateAccountNumber(payload) {
        return ServiceHelper.handleGetRequest(`/add_bank/?action=validate&code=${payload.code}&account_number=${payload.account_number}`);
    }
    
    static createPaycode(payload){
        return ServiceHelper.handlePostRequest('/create_paycode/', payload);
    }
    
    static bulkCreatePaycode(payload){
        return ServiceHelper.handlePostRequest('/create_bulk_paycode/', payload);
    }

    static checkPaycodeBalance(payload) {
        return ServiceHelper.handleGetRequest(`/paycode_balance/?token=${payload.paycode}&secret=${payload.secret_word}`);
    }
     
    static confirmPaycode(payload){
        return ServiceHelper.handlePostRequest('/confirm_paycode/', payload);
    }

    // static getMerchantPaycode(){
    //     return ServiceHelper.handleGetRequest(`${this.BASE_URL}/paycodes/`);
    // }

    static claimPaycode(payload){
        return ServiceHelper.handlePostRequest('/claim_paycode/', payload);
    }

    static applyPaycode(payload){
        return ServiceHelper.handlePostRequest('/paycode/redeem/', payload);
    }

    static addAccountDetails(payload) {
        return ServiceHelper.handlePostRequest('/add_bank/', payload);
    }

    // static transactionHistory(params) {
    //     let request_params = ``;

    //     if(params.from_date != '') {
    //         request_params += `&from_date=${params.from_date}`; 
    //     }
    //     if(params.to_date != ''){
    //         request_params += `&to_date=${params.to_date}`;
    //     }
    //     if(params.status == 'failed') {
    //         request_params += `&status=${params.status}`;
    //     }
    //     if(params.download != '' && params.download == 'True') {
    //         request_params += `&download=${params.download}`;
    //     }
    //     if(params.page != '') {
    //         request_params += `&page=${params.page}`;
    //     }
    //     if(params.merchant != '') {
    //         request_params += `&merchant=${params.merchant}`;
    //     }
    //     request_params = request_params.slice(1)
    //     return ServiceHelper.handleGetRequest(`${this.BASE_URL}/transactions/?${request_params}`);
    // }

    static getBills(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== 'undefined') {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== 'undefined'){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.status !== '' && params.status !== 'undefined') {
            request_params += `&status=${params.status}`;
        }
        if(params.filter !== '' && params.filter !== 'undefined') {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== 'undefined') {
            request_params += `&filter_by=${params.filter_by}`;
        }
        if(params.sort_by !== '' && params.sort_by !== 'undefined') {
            request_params += `&sort_by=${params.sort_by}`;
        }
        if(params.download !== '' && params.download !== 'undefined' && params.download == 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.gateway !== '' && params.gateway !== undefined) {
            request_params += `&gateway=${params.gateway}`;
        }
        if(params.page !== '' && params.page !== 'undefined') {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/transactions/bills/?${request_params}`);
    }

    static getP2p(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== 'undefined') {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== 'undefined'){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.status !== '' && params.status !== 'undefined') {
            request_params += `&status=${params.status}`;
        }
        if(params.filter !== '' && params.filter !== 'undefined') {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== 'undefined') {
            request_params += `&filter_by=${params.filter_by}`;
        }
        if(params.sort_by !== '' && params.sort_by !== 'undefined') {
            request_params += `&sort_by=${params.sort_by}`;
        }
        if(params.download !== '' && params.download !== 'undefined' && params.download == 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.gateway !== '' && params.gateway !== undefined) {
            request_params += `&gateway=${params.gateway}`;
        }
        // if(params.merchant != '') {
        //     request_params += `&merchant=${params.merchant}`;
        // }
        if(params.page !== '' && params.page !== 'undefined') {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/paycodes/p2p?${request_params}`);
    }

    static getMerchants(params) {
        let request_params = ``;

        if(params.from_date != '') {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date != ''){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.status == 'failed') {
            request_params += `&status=${params.status}`;
        }
        if(params.download != '' && params.download == 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.page != '' && params.download == '') {
            request_params += `&page=${params.page}`;
        }
        if(params.request_type != '') {
            request_params += `&request_type=${params.request_type}`;
        }
        if(params.filter_by === 'type' && params.filter != '') {
            request_params += `&filter_by=type&filter=${params.filter}`;
        }
        else if(params.filter && params.filter != '') {
            request_params += `&filter_by=name&filter=${params.filter}`;
        }
        request_params = request_params.slice(1)
        if(params.download == 'True') {
            return ServiceHelper.handleDownloadRequest(`${this.BASE_URL}/merchants/?${request_params}`); 
        }
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/merchants/?${request_params}`);
    }

    static getMerchantById(id) {
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/merchants/detail/${id}`);
    }

    static generateReport(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.filter_by === 'type'){
            request_params += `&transaction_type=${params.filter}`;
        }
        if(params.status !== '' && params.status !== undefined) {
            request_params += `&status=${params.status}`;
        }
        if(params.filter !== '' && params.filter !== undefined) {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== undefined) {
            request_params += `&filter_by=${params.filter_by}`;
        }
        if(params.sort_by !== '' && params.sort_by !== undefined) {
            request_params += `&sort_by=${params.sort_by}`;
        }
        if(params.download !== '' && params.download !== undefined && params.download == 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.gateway !== '' && params.gateway !== undefined) {
            request_params += `&gateway=${params.gateway}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return ServiceHelper.handleGetRequest(`/transaction_history/?${request_params}`);
    }

    static getInvoices() {
        return ServiceHelper.handleGetRequest(`/merchant_invoice/`);
    }

    static getInvoicesByRef(ref) {
        return ServiceHelper.handleGetRequest(`/merchant_invoice/?ref=${ref}`);
    }
    
    static createInvoice(payload) {
        return ServiceHelper.handlePostRequest(`/merchant_invoice/`, payload);
    }
    
    static invoicePayment(payload) {
        return ServiceHelper.handlePostRequest(`/pay_invoice/${payload.invoice_id}/`, payload);
    }

    static getVouchers() {
        return ServiceHelper.handleGetRequest(`/user_vouchers/`);
    }
    
    // static analytics() {
    //     return ServiceHelper.handleGetRequest(this.BASE_URL+"/dashboard_analytics");
    // }

    static analytics(params) {
        let url = "/dashboard_analytics"
        // if(params.type !== 'day') {
        //     url = "/dashboard_analytics/?type="+params.type
        // }
        // else if(params.type === 'day' && params.date !== 'undefined' && params.date !== undefined) { 
        //     url = `/dashboard_analytics/?type=${params.type}&date=${params.date}`
        // }
        // else if(params.from_date !== 'undefined' && params.to_date !== 'undefined') { 
        //     url = `/dashboard_analytics/?type=date&from_date=${params.from_date}&to_date=${params.to_date}`
        // }
        return ServiceHelper.handleGetRequest(this.BASE_URL+url);
    }
    
    static verifyInvoice(payload) {
        return ServiceHelper.handlePostRequest("/verify_invoice/", payload);
    }
    
    static announcements() {
        return ServiceHelper.handleGetRequest("/announcements/");
    }
    
    static auditTrail(params) {
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/paycodes/audit_trail?paycode=${params.paycode}&reference=${params.reference}`);
    }

    static businessStatusManagement(payload) {
        let uri  
        let status
        switch (payload.option) {
            case "approve": 
                status = "approve";
                uri = "approve";
                break;

            case "activate": 
                status = "approve";
                uri = "approve";
                break;

            case "deactivate": 
                status = "decline";
                uri = "approve";
                break;

            case "archive": 
                status = "archive";
                uri = "archive";
                break;

            case "unarchive": 
                status = "unarchive";
                uri = "archive";
                break;
                
            case "lock": 
                status = "lock";
                uri = "lock_wallet";
                break;
                
            case "unlock": 
                status = "unlock";
                uri = "lock_wallet";
                break;
                
            case "limit": 
                status = "";
                uri = "set_limit";
                break;
        
            default:
                break;
        }

        let request_payload = payload.option === "limit" ?  { limit: payload.limit } : { status: status };
        return ServiceHelper.handlePutRequest(`${this.BASE_URL}/merchants/${uri}/${payload.id}`, request_payload)
    }

    static getAdmins() {
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/settings/manage_admin`);
    }

    static deleteAdmin(email) {
        return ServiceHelper.handleDeleteRequest(`${this.BASE_URL}/settings/manage_admin`, {email: email});
    }

    static addAdmin(payload) {
        return ServiceHelper.handlePostRequest(`${this.BASE_URL}/settings/manage_admin`, payload);
    }

    static deactivateAdmin(payload) {
        return ServiceHelper.handlePutRequest(`${this.BASE_URL}/settings/manage_admin`, payload);
    }

    static getWithrawalLimits() {
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/merchants/withdrawal_limit`);
    }

    static manageWithrawalLimits(payload) {
        return ServiceHelper.handlePutRequest(`${this.BASE_URL}/merchants/withdrawal_limit`, {limit_request_id: payload.id, status: payload.status});
    }

    static managePaycode(payload) {
        return ServiceHelper.handlePostRequest(`${this.BASE_URL}/paycodes/lock`, payload)
    }

    static emailValidation(payload) {
        return ServiceHelper.handlePutRequest(`${this.BASE_URL}/merchants/email_validate`, payload)
    }

    static getMerchantPaycode(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== 'undefined') {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== 'undefined'){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.status !== '' && params.status !== 'undefined') {
            request_params += `&status=${params.status}`;
        }
        if(params.filter !== '' && params.filter !== 'undefined') {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== 'undefined') {
            request_params += `&filter_by=${params.filter_by}`;
        }
        if(params.sort_by !== '' && params.sort_by !== 'undefined') {
            request_params += `&sort_by=${params.sort_by}`;
        }
        if(params.download !== '' && params.download !== 'undefined' && params.download == 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.gateway !== '' && params.gateway !== undefined) {
            request_params += `&gateway=${params.gateway}`;
        }
        if(params.page !== '' && params.page !== 'undefined') {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/paycodes/?${request_params}`);
    }

    static transactionHistory(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== 'undefined') {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== 'undefined'){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.status !== '' && params.status !== 'undefined') {
            request_params += `&status=${params.status}`;
        }
        if(params.filter !== '' && params.filter !== 'undefined') {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== 'undefined') {
            request_params += `&filter_by=${params.filter_by}`;
        }
        if(params.sort_by !== '' && params.sort_by !== 'undefined') {
            request_params += `&sort_by=${params.sort_by}`;
        }
        if(params.download !== '' && params.download !== 'undefined' && params.download == 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.gateway !== '' && params.gateway !== undefined) {
            request_params += `&gateway=${params.gateway}`;
        }
        if(params.merchant != '') {
            request_params += `&merchant=${params.merchant}`;
        }
        if(params.page !== '' && params.page !== 'undefined') {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/transactions/?${request_params}`);
    }

    static getCharges() {
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/transactions/transaction_charges/`);
    }

    static updateTransferCharges(payload) {
        return ServiceHelper.handlePutRequest(`${this.BASE_URL}/transactions/transaction_charges/`, payload);
    }

    static getEarnings() {
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/transactions/earnings/`);
    }

    static createLoyaltyPaycode(payload){
        return ServiceHelper.handlePostRequest(`${this.BASE_URL}/loyalty_voucher/`, payload);
    }


    static updateLoyaltyVoucherCharge(payload){
        return ServiceHelper.handlePutRequest(`${this.BASE_URL}/paycodes/loyalty_voucher`, payload);
    }


    static getLoyaltyPaycodeHistory(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        if(params.paycode_id !== '' && params.paycode_id !== undefined) {
            request_params += `&merchan_id=${params.merchan_id}`;
        }
        request_params = request_params.slice(1)
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/paycodes/loyalty_voucher?${request_params}`);
    }

    static getExchangeRates() {
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/transactions/exchange_rate/`);
    }

    static updateExchangeRate(payload) {
        return ServiceHelper.handlePutRequest(`${this.BASE_URL}/transactions/exchange_rate/`, payload);
    }

    static transactionData() {
        return ServiceHelper.handleGetRequest(`${this.BASE_URL}/transactions/analytics/`);
    }
    
}